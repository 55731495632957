import React from 'react'
import moment from 'moment'
import { WEB_SEARCH_LINK } from '../../../config'
import bigbrandCarImage from '../../../static/assets/bigbrand-car.min.png'
import './style.scss'

const Card = props => {
  return (
    <div className="col-6 col-lg-3">
      <div
        className="card border-secondary mb-3"
        onClick={() => (window.location = props.url)}
      >
        <div className="card-body">
          <p className="card-title">{props.title}</p>
          <div className="content-wrapper--price">
            <div className="clearfix">
              <p className="float-left">
                <small>เริ่มต้น</small>
              </p>
              <p className="float-right">
                <span className="text-pricing--primary">
                  {parseInt(props.at_least_price).toLocaleString()}{' '}
                </span>
                <small>บาท</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ProvinceSection(props) {
  const [state, setState] = React.useState({
    expand: false,
  })
  const {
    prefix,
    data,
    title,
    rentalProviderImage,
    rentalProviderImageAlt,
    propsImageAlt,
    isMobile,
  } = props
  const divisor = isMobile ? 8 : 12
  const { expand } = state
  const activeLocations = data.filter(lo => lo.activate)
  const justifyData = expand
    ? activeLocations
    : activeLocations.filter((e, i) => i < divisor)
  const toggle = () => {
    setState({ ...state, expand: !expand })
  }

  const composeUrl = locationID => {
    const { brandId } = props
    let params = {
      booking_begin: `${moment()
        .add(7, 'days')
        .format('YYYY-MM-DD')} 10:00`,
      booking_end: `${moment()
        .add(9, 'days')
        .format('YYYY-MM-DD')} 10:00`,
      location_id: locationID,
    }
    params = { ...params, dealers: brandId }
    const parsed = Object.keys(params).reduce(
      (p, c) => (p += `${c}=${params[c]}&`),
      '?',
    )
    return `${WEB_SEARCH_LINK}/${parsed}`
  }

  return (
    <div className="container province-section__container">
      <h2 className="title">
        ดีลรถเช่า ให้บริการโดย{' '}
        <img
          alt={rentalProviderImageAlt}
          className="image--brand"
          src={rentalProviderImage}
        />
      </h2>
      <p className="title">{title}</p>
      <img
        alt={propsImageAlt}
        className="props--image"
        src={bigbrandCarImage}
      />
      <div className="row justify-content-between card-wrapper">
        {justifyData.map((item, index) => (
          <Card
            key={index}
            at_least_price={item.at_least_price}
            title={item[`title_${prefix}`]}
            url={composeUrl(item.location_id)}
          />
        ))}
      </div>
      {activeLocations.length <= divisor ? (
        ''
      ) : !expand ? (
        <button
          type="button"
          onClick={toggle}
          className="btn btn-lg btn-outline-primary btn-square"
        >
          ดูเพิ่มเติม
        </button>
      ) : (
        <button
          type="button"
          onClick={toggle}
          className="btn btn-lg btn-outline-primary btn-square"
        >
          แสดงน้อยลง
        </button>
      )}
    </div>
  )
}

export default ProvinceSection
