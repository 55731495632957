import React, { Component } from 'react'
import './style.scss'

class Panels extends Component {
  state = {
    open: [0],
  }
  static defaultProps = {
    header: '',
  }
  get panels() {
    return this.props.panels || []
  }
  googleFaqConfig = show_google_faq => {
    const googleConfig = {
      itemScope: 'itemScope',
      itemProp: 'mainEntity',
      itemType: 'https://schema.org/Question',
    }
    return show_google_faq ? googleConfig : {}
  }
  get googleFaqHeader() {
    return this.props.isContentPage
      ? {}
      : {
          itemScope: 'itemScope',
          itemType: 'https://schema.org/FAQPage',
        }
  }
  render() {
    const { city, header } = this.props

    return (
      <div className="panels__container">
        {/* Declare section for Google FAQ */}
        <div className="container panels__box" {...this.googleFaqHeader}>
          {!this.props.noHeader ? (
            <h2>เช่ารถ{city}ราคาถูก</h2>
          ) : (
            <h2>{header}</h2>
          )}
          {this.panels.map(({ title, content, show_google_faq }, index) => (
            // Set mainEntity each question for Google FAQ.
            <div
              key={title}
              className={this.state.open.includes(index) ? 'open' : ''}
              {...this.googleFaqConfig(show_google_faq)}
            >
              <div
                onClick={() =>
                  this.setState({
                    open: this.state.open.includes(index)
                      ? this.state.open.filter(o => o !== index)
                      : this.state.open.concat(index),
                  })
                }
                className={`panel--title`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div
                className={this.state.open.includes(index) ? 'show' : 'hide'}
              >
                {typeof content === 'string' ? (
                  <article
                    className={`panel--content`}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                ) : (
                  content
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
export default Panels
