import React from 'react'
import LazyLoad from 'react-lazyload'
import './style.scss'
export function ShortValuePropList({
  containerClass,
  itemClass,
  prefix = 'th',
  siteDescription = [],
}) {
  return (
    <div className={containerClass}>
      {siteDescription.map(item => (
        <div key={item[`content_${prefix}`]} className={itemClass}>
          <i className="flaticon-checked-circle" />
          <span>{item[`content_${prefix}`]}</span>
        </div>
      ))}
    </div>
  )
}

const HeaderValueProps = props => (
  <div className="container header-value-props__container">
    <LazyLoad>
      <img src="/assets/boy02.png" alt="Drivehub" />
    </LazyLoad>
    <ShortValuePropList
      {...props}
      containerClass="value-props__list"
      itemClass="prop-item"
    />
  </div>
)

export default HeaderValueProps
