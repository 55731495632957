import React from 'react'
import MediaQuery from 'react-responsive'
import { ShortValuePropList } from '../header-value-props'
import PropTypes from 'prop-types'
import { SearchBox } from 'drivehub-rc'
import './style.scss'

const Search = props => {
  const {
    prefix,
    locationId,
    title,
    subTitle,
    isHomepage,
    rentalProviderImage,
    rentalProviderImageAlt,
    siteDescription,
    brandId,
    variant,
  } = props
  const variantClass = variant === 'dense' ? 'content-dense' : ''

  return (
    <div className={`container search ${isHomepage ? 'search-homepage' : ''}`}>
      <div className={`search__content ${variantClass}`}>
        <div className="search__panel--top">
          <MediaQuery maxWidth={768}>
            <HeaderTitle
              title={title}
              rentalProviderImage={rentalProviderImage}
              rentalProviderImageAlt={rentalProviderImageAlt}
              subTitle={subTitle}
            />
          </MediaQuery>
        </div>
        <div className="search__panel--left">
          <SearchBox locationID={parseInt(locationId)} vendorID={brandId} />
        </div>
        <div className="search__panel--right">
          <div>
            <HeaderTitle
              title={title}
              rentalProviderImage={rentalProviderImage}
              rentalProviderImageAlt={rentalProviderImageAlt}
              subTitle={subTitle}
            />
          </div>
          <ShortValuePropList
            prefix={prefix}
            rentalProviderImage={rentalProviderImage}
            rentalProviderImageAlt={rentalProviderImageAlt}
            containerClass="list-items"
            itemClass="list-item"
            siteDescription={siteDescription}
          />
        </div>
      </div>
    </div>
  )
}

const Subtitle = props => {
  return <p className="interbrand-typography">จาก {props.children}</p>
}

const HeaderTitle = props => {
  const { rentalProviderImage, rentalProviderImageAlt } = props
  return (
    <div className="search--header">
      <h1 dangerouslySetInnerHTML={{ __html: props.title }} />
      {rentalProviderImage ? (
        <Subtitle>
          <img
            alt={rentalProviderImageAlt}
            className="brand"
            src={rentalProviderImage}
          />
        </Subtitle>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: props.subTitle }} />
      )}
    </div>
  )
}
Search.propTypes = {
  variant: PropTypes.oneOf(['dense', 'normal']),
}
Search.defaultProps = {
  variant: 'normal',
}

export default Search
