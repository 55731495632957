import React from 'react'
import styled, { css } from 'styled-components'

export const DhArrow = props => {
  const { type, onClick, slideCount, currentSlide, slidesToShow } = props
  const maxSlice = slideCount - slidesToShow
  return (
    <Arrow
      onClick={onClick}
      id={`slick-arrow-${type}`}
      type={type}
      currentSlide={currentSlide}
      maxSlice={maxSlice}
    >
      <i className={`${type === 'next' ? 'flaticon-next' : 'flaticon-back'}`} />
    </Arrow>
  )
}

const Arrow = styled.div`
  position: absolute;
  top: 43.5%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  border: solid 1px #eeeeee;
  border-radius: 21px;
  z-index: 10;
  :hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  }
  i:before {
    color: #0078ff;
    font-size: 16px;
    ${props =>
      props.type === 'next'
        ? css`
            padding-left: 3px;
          `
        : css`
            padding-right: 3px;
          `}
  }
  ${props =>
    props.type === 'next'
      ? css`
          right: calc(0% - 9vh);
          @media screen and (max-width: 1350px) {
            right: calc(0% - 5vh);
          }
          ${props.currentSlide === props.maxSlice
            ? css`
                i:before {
                  color: #eef0f2;
                }
                :hover {
                  box-shadow: unset;
                }
              `
            : ''}
        `
      : css`
          left: calc(0% - 9vh);
          @media screen and (max-width: 1350px) {
            left: calc(0% - 5vh);
          }
          ${props.currentSlide === 0
            ? css`
                i:before {
                  color: #eef0f2;
                }
                :hover {
                  box-shadow: unset;
                }
              `
            : ''}
        `}
`

export const DhPaging = ({ onClick }) => {
  return <Paging onClick={onClick} />
}

const Paging = styled.div`
  background-color: #9b9b9b;
  width: 10px;
  height: 10px;
  opacity: 0.8;
  border-radius: 6px;
  transition: background-color 0.3s;
  transition-timing-function: ease;
`

export const DhDots = ({ className, children, modifyClassName }) => {
  return (
    <Dots className={`${className} ${modifyClassName ? modifyClassName : ''}`}>
      {children}
    </Dots>
  )
}

const Dots = styled.div`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: -30px !important;

  ul {
    margin: 0px !important;
  }
  li {
    width: unset !important;
    height: unset !important;
    margin: 0px 8px !important;
  }
  .slick-active {
    div {
      background-color: #0078ff;
      opacity: 0.9;
    }
  }
`
