import React from 'react'
import './style.scss'

export const Card = ({ className, children }) => {
  return (
    <div className={`card-container ${className ? className : ''}`}>
      {children}
    </div>
  )
}
