import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Background = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { extension: { regex: "/png|jpg|jpeg/" } }) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 4160) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
    >
      {({ images }) => {
        const selected = images.edges.find(image => '/' + image.node.relativePath === props.src)
        return (
          <BackgroundImage
            Tag="section"
            className={props.className}
            fluid={selected.node.childImageSharp.fluid}
          >
            {props.children}
          </BackgroundImage>
        )
      }}
    </StaticQuery>
  )
}

export default Background
