import React, { Component } from 'react'
import { graphql } from 'gatsby'
import NotFound from '../../components/NotFound'
import Layout from '../../components/layout'
import Background from '../../components/background'
import Panels from '../../components/panels'
import SEO from '../../components/seo'
import Search from '../../components/search'
import BrandList from '../../components/brand-list'
import StickyButton from '../../components/sticky-button'
import PromotionList from '../../components/promotion-list'
import ProvinceSection from '../../components/province-section'
import { BIGBRAND_API } from '../../../config'
import axios from 'axios'
import { Context, Theme } from 'drivehub-rc'
import './style.scss'

class InterBrand extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: true,
      locations: [],
    }
  }
  fetchLocations = vendorsId => {
    axios.get(`${BIGBRAND_API}/vendor/${vendorsId}/locations`).then(res => {
      this.setState({ locations: res.data })
    })
  }

  componentDidMount() {
    const data = this.props.data
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter } = markdownRemark
    // NOTE: Check Responsive Window
    const initialWidth = window.innerWidth
    this.setState({ isMobile: initialWidth <= 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 991px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
    this.fetchLocations(frontmatter.brand_id)
  }

  render() {
    const data = this.props.data
    const { isMobile, locations } = this.state
    const { markdownRemark, site_description } = data // data.markdownRemark holds our post data
    const defaultShortValueProps =
      site_description.edges[0].node.frontmatter.short_value_props
    const { frontmatter } = markdownRemark
    const headerShortValueProps = frontmatter.short_value_props
      ? frontmatter.short_value_props
      : defaultShortValueProps
    const prefix = this.props.pathContext.prefix
    const altDefault = frontmatter[`sub_title_${prefix}`]

    return !frontmatter.activate ? (
      <NotFound />
    ) : (
      <Theme>
        <Context isMobile={isMobile}>
          <Layout
            isMobile={isMobile}
            prefix={prefix}
            menuStyle={
              isMobile
                ? null
                : { position: 'absolute', width: '100%', zIndex: 10 }
            }
            noCityList
          >
            <SEO
              prefix={prefix}
              title={frontmatter[`header_${prefix}`]}
              canonical={frontmatter[`rel_canonical_${prefix}`]}
              path={frontmatter.path}
              description={frontmatter[`description_${prefix}`]}
              keywords={frontmatter[`keywords_${prefix}`]}
            />
            <div className="header">
              <Background src={frontmatter.hero} className="header--bg-city">
                <Search
                  prefix={prefix}
                  locations={locations}
                  brandId={frontmatter.brand_id}
                  title={`ค้นหารถว่าง ราคาถูกที่สุด`}
                  variant="dense"
                  brandName={frontmatter[`name_${prefix}`]}
                  rentalProviderImage={
                    frontmatter[`brand_white_image_${prefix}`]
                  }
                  rentalProviderImageAlt={
                    frontmatter[`brand_white_image_${prefix}_alt`]
                      ? frontmatter[`brand_white_image_${prefix}_alt`]
                      : altDefault
                  }
                  siteDescription={headerShortValueProps}
                  isInterBrand
                />
              </Background>
            </div>

            <div className="blog-post">
              <div className="container promotion__container">
                <PromotionList
                  interbrandName={frontmatter.name_en}
                  prefix={prefix}
                  isMobile={isMobile}
                  isInterPage
                />
              </div>
              <ProvinceSection
                rentalProviderImage={frontmatter[`brand_image_${prefix}`]}
                rentalProviderImageAlt={
                  frontmatter[`brand_image_${prefix}_alt`]
                    ? frontmatter[`brand_image_${prefix}_alt`]
                    : altDefault
                }
                propsImageAlt={
                  frontmatter[`props_image_${prefix}_alt`]
                    ? frontmatter[`props_image_${prefix}_alt`]
                    : altDefault
                }
                title={frontmatter[`sub_title_${prefix}`]}
                data={frontmatter.provinces}
                isMobile={isMobile}
                prefix={prefix}
                brandId={frontmatter.brand_id}
              />
              <BrandList prefix={prefix} brands={frontmatter.other_brands} />
              <Panels
                prefix={prefix}
                panels={
                  frontmatter.panels &&
                  frontmatter.panels.map(item => ({
                    title: item[`title_${prefix}`],
                    content: item[`content_${prefix}`],
                    show_google_faq: item.show_google_faq,
                  }))
                }
                noHeader
                header="คำถามและข้อสงสัย"
              />
            </div>
            <StickyButton prefix={prefix} />
          </Layout>
        </Context>
      </Theme>
    )
  }
}
export default InterBrand
export const query = graphql`
  query($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      html
      frontmatter {
        name_th
        name_en
        activate
        path
        header_th
        header_en
        sub_title_th
        sub_title_en
        description_th
        description_en
        keywords_th
        keywords_en
        rel_canonical_th
        rel_canonical_en
        brand_white_image_th
        brand_white_image_en
        brand_image_th
        brand_image_en
        brand_white_image_th_alt
        brand_white_image_en_alt
        brand_image_th_alt
        brand_image_en_alt
        props_image_th_alt
        props_image_en_alt
        hero
        brand_id
        short_value_props {
          content_th
          content_en
        }
        other_brands {
          name_th
          name_en
          url
          image
          image_alt_th
          image_alt_en
          activate
        }
        provinces {
          title_th
          title_en
          at_least_price
          location_id
          activate
        }
        panels {
          title_th
          title_en
          content_th
          content_en
          show_google_faq
        }
      }
    }
    boy01: file(relativePath: { eq: "assets/boy-01.png" }) {
      childImageSharp {
        fluid(maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site_description: allMarkdownRemark(
      filter: { fields: { collection: { eq: "interbrand_description" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            short_value_props {
              content_th
              content_en
            }
          }
        }
      }
    }
  }
`
