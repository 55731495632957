import React from 'react'
import { Link } from 'gatsby'
import './style.scss'
import MediaQuery from 'react-responsive'
function BrandList(props) {
  const { prefix, brands } = props
  const activeBrands = brands.filter(b => b.activate)
  return (
    <div className="brandlist-section__container">
      <div className="container">
        <div className="item-list--wrapper">
          <h2 className="brand-list--title">ผู้ให้บริการรถเช่าอื่นๆ</h2>
          <div className="row justify-content-center">
            {activeBrands.map((item, index) => (
              <div key={index} className="col-10 col-md-7 col-lg-3">
                <MediaQuery maxWidth={960}>
                  <div className="brand-card">
                    <div className="row no-gutters d-flex align-items-center">
                      <div className="col-7">
                        <Link to={`/${prefix}/${item.url}`}>
                          <div className="brand-thumb">
                            <img
                              height={30}
                              alt={
                                item[`image_alt_${prefix}`]
                                  ? item[`image_alt_${prefix}`]
                                  : item[`name_${prefix}`]
                              }
                              src={item.image}
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="col-5">
                        <Link to={`/${prefix}/${item.url}`}>
                          <h4>{item[`name_${prefix}`]}</h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </MediaQuery>
                <MediaQuery className="brand-card" minWidth={961}>
                  <Link to={`/${prefix}/${item.url}`}>
                    <div className="brand-thumb">
                      <img
                        height={30}
                        alt={item[`image_alt_${prefix}`]}
                        src={item.image}
                      />
                    </div>
                    <h4>{item[`name_${prefix}`]}</h4>
                  </Link>
                </MediaQuery>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandList
