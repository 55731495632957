import React, { Component } from 'react'
import { Card } from '../card'
import propTypes from 'prop-types'

export default class PromotionCard extends Component {
  static propTypes = {
    avatar: propTypes.object,
    name: propTypes.string,
    label: propTypes.string,
    path: propTypes.string,
    short_description: propTypes.string,
    button: propTypes.string,
    prefix: propTypes.string,
  }
  render() {
    const {
      avatar,
      name,
      // label,
      path,
      short_description,
      button,
      prefix,
      gtm_tracking_id,
    } = this.props

    const btnTrackingId = gtm_tracking_id ? { id: gtm_tracking_id } : {}

    return (
      <Card className={'card--promotion'}>
        <a href={`${prefix}/${path}`} className="promo-img-link">
          {avatar}
        </a>
        <section>
          <div>
            {/* <label>{label}</label> */}
            <h2>{name}</h2>
            <p>{short_description}</p>
          </div>
          <a href={`${prefix}/${path}`}>
            <button {...btnTrackingId}>{button}</button>
          </a>
        </section>
      </Card>
    )
  }
}
