import React, { PureComponent } from 'react'
import Slider from 'react-slick/lib/slider'
import { LoadingWheel } from '../loading'
import { DhDots, DhArrow, DhPaging } from './custom-slick'
import './style.scss'

class DhSlick extends PureComponent {
  slickDefaultSettings = () => {
    const { settings } = this.props
    const { appendDots } = settings
    const newSettings = {
      nextArrow: <DhArrow type={'next'} slidesToShow={settings.slidesToShow} />,
      prevArrow: <DhArrow type={'prev'} slidesToShow={settings.slidesToShow} />,
      customPaging: i => <DhPaging i={i} />,
      ...settings,
    }
    if (appendDots) {
      return newSettings
    } else {
      newSettings['appendDots'] = dots => (
        <DhDots>
          <ul>{dots}</ul>
        </DhDots>
      )
    }

    return newSettings
  }
  render() {
    const { children, isLoading } = this.props

    return (
      <div>
        {isLoading ? (
          <div
            style={{ minHeight: 350, display: 'flex', alignItems: 'center' }}
          >
            <LoadingWheel height={40} width={40} />
          </div>
        ) : (
          <Slider {...this.slickDefaultSettings()}>{children}</Slider>
        )}
      </div>
    )
  }
}

export default DhSlick
