import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Card } from '../card'
import Img from 'gatsby-image'
import PromotionCard from './card'
import DhSlick from '../slick'
import './style.scss'

const PromotionList = ({
  prefix = 'th',
  isMobile = false,
  interbrandName,
  cityId,
  isHomePage,
  isCityPage,
  isInterPage,
}) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { extension: { regex: "/png|jpg|jpeg/" } }) {
          edges {
            node {
              extension
              relativePath
              childImageSharp {
                fluid(maxWidth: 4000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        promotions: allMarkdownRemark(
          filter: { fields: { collection: { eq: "promotion" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                promotion_path
                position
                name_th
                name_en
                short_description_th
                short_description_en
                label_th
                label_en
                src_th
                src_en
                src_mobile_th
                src_mobile_en
                button_th
                button_en
                activate
                interbrand
                citypage
                gtm_tracking_id
              }
            }
          }
        }
      }
    `}
    render={data => {
      const images = data.images.edges.map(({ node }) => node)
      const promotions = data.promotions.edges
        .map(({ node }) => node.frontmatter)
        .filter(p => p.activate === true)
        .sort((a, b) => {
          return (
            a.position && b.position && Number(a.position) - Number(b.position)
          )
        })
        .map(item => {
          const src = isMobile
            ? item[`src_mobile_${prefix}`]
            : item[`src_${prefix}`]
          return {
            interbrand: item.interbrand,
            label: item[`label_${prefix}`],
            name: item[`name_${prefix}`],
            short_description: item[`short_description_${prefix}`],
            sharp: images.find(image => '/' + image.relativePath === src),
            button: item[`button_${prefix}`],
            path: item.promotion_path,
            citypage: item.citypage,
            gtm_tracking_id: item.gtm_tracking_id,
          }
        })

      return (
        <Slider
          interbrandName={interbrandName}
          promotions={promotions}
          isMobile={isMobile}
          prefix={prefix}
          cityId={cityId}
          isHomePage={isHomePage}
          isCityPage={isCityPage}
          isInterPage={isInterPage}
        />
      )
    }}
  />
)
const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  // autoplay: true,
  // autoplaySpeed: 8000,
}

class Slider extends React.Component {
  getPromotionList = promotions => {
    const {
      interbrandName,
      cityId,
      isHomePage,
      isCityPage,
      isInterPage,
    } = this.props
    const promotionsList = isHomePage
      ? promotions
      : promotions.filter(item => {
          if (isInterPage) {
            return (
              item.interbrand && item.interbrand.find(e => e === interbrandName)
            )
          } else if (isCityPage) {
            return (
              item.citypage && item.citypage.find(e => e === cityId.toString())
            )
          }
          return item
        })
    return promotionsList
  }
  render() {
    const { promotions, prefix } = this.props
    const promotionList = this.getPromotionList(promotions)

    return (
      <React.Fragment>
        {promotionList.length > 0 && (
          <React.Fragment>
            <div
              className="promotion-box"
              style={promotionList.length > 1 ? { paddingBottom: '24px' } : {}}
            >
              <DhSlick settings={sliderSettings}>
                {promotionList.map(promotion => (
                  <PromotionCard
                    key={promotion.name}
                    avatar={
                      <Img
                        fluid={promotion.sharp.childImageSharp.fluid}
                        className="promo-image"
                      />
                    }
                    prefix={prefix}
                    {...promotion}
                  />
                ))}
              </DhSlick>
            </div>
            <div className="promotion-box--mobile">
              {promotionList.map(promotion => (
                <PromotionCard
                  key={promotion.name}
                  avatar={
                    <Img
                      fluid={promotion.sharp.childImageSharp.fluid}
                      className="promo-image"
                    />
                  }
                  prefix={prefix}
                  {...promotion}
                />
              ))}
              <Card className={'card--last-child'} />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default PromotionList
